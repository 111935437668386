import { productTypes } from '@nexusmutual/sdk';
import { DAO_MULTISIG_ADDRESS, UNITY_ADDRESS } from 'Config';
import { ProductType } from 'Features/Membership/ducks/covers/initialState';

/* eslint-disable no-unused-vars */
// using lowercase values here to match the values we have in the products.json file (both v1 and v2)
export enum PRODUCT_TYPES {
  protocol, // 0
  custodian, // 1
  token, // 2
  sherlockExcess, // 3
  stakewiseEthStaking, // 4
  lcEthStaking, // 5
  ethSlashing, // 6
  sherlockQuotaShare, // 7
  nativeProtocol, // 8
  retailMutual, // 9
  unoreQuotaShare, // 10
  bundledProtocol, // 11
  ethSlashingUmbrella, // 12
  openCoverTransaction, // 13
  fundPortfolio, // 14
  sherlockBugBounty, // 15
  defiPass, // 16
  followOn, // 17
  immunefiBugBounty, // 18
  nexusmutual, // 19
  generalisedFundPortfolio, // 20
  crypto, // 21
  nativeSyndicate, // 22
}

const ETH_SLASHING = [
  PRODUCT_TYPES.ethSlashing,
  PRODUCT_TYPES.lcEthStaking,
  PRODUCT_TYPES.stakewiseEthStaking,
];

const SHERLOCK = [PRODUCT_TYPES.sherlockExcess, PRODUCT_TYPES.sherlockQuotaShare];

const QUOTA_SHARE = [
  PRODUCT_TYPES.unoreQuotaShare,
  PRODUCT_TYPES.sherlockQuotaShare,
  PRODUCT_TYPES.sherlockBugBounty,
];

const WITH_PROOF_OF_LOSS_PROTOCOL = [PRODUCT_TYPES.protocol, PRODUCT_TYPES.bundledProtocol];

const UMBRELLA = [PRODUCT_TYPES.ethSlashingUmbrella]; // new group in case we'll have similar types in the future

const OPEN_COVER_NO_PROOF_OF_LOSS = [PRODUCT_TYPES.openCoverTransaction];

export const PRODUCT_GROUP = {
  ETH_SLASHING,
  SHERLOCK,
  QUOTA_SHARE,
  PROTOCOL: WITH_PROOF_OF_LOSS_PROTOCOL,
  UMBRELLA,
  OPEN_COVER_NO_PROOF_OF_LOSS,
};

export enum CLAIM_METHOD {
  CLAIM,
  INCIDENT,
}

export const getClaimMethod = (type: ProductType) => productTypes[type].claimMethod;

export const BUY_COVER_PRICE_DENOMINATOR = 100_00;
export const BUY_COVER_COMMISSION_RATIO = 15_00; // 15%
export const BUY_COVER_TRM_COMMISSION_RATIO = 10_00; // 10%
export const FUND_PORTFOLIO_COMMISSION_RATIO = 10_00; // 10%
export const DEFI_PASS_COMMISSION_RATIO = 10_00; // 10%

export const BUY_COVER_COMMISSION_RATIO_BY_PRODUCT_TYPE: Record<number, number> = {
  [PRODUCT_TYPES.protocol]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.custodian]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.token]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.sherlockExcess]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.stakewiseEthStaking]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.lcEthStaking]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.ethSlashing]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.sherlockQuotaShare]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.nativeProtocol]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.retailMutual]: BUY_COVER_TRM_COMMISSION_RATIO,
  [PRODUCT_TYPES.unoreQuotaShare]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.bundledProtocol]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.ethSlashingUmbrella]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.openCoverTransaction]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.fundPortfolio]: FUND_PORTFOLIO_COMMISSION_RATIO,
  [PRODUCT_TYPES.sherlockBugBounty]: BUY_COVER_COMMISSION_RATIO,
  [PRODUCT_TYPES.defiPass]: DEFI_PASS_COMMISSION_RATIO,
  [PRODUCT_TYPES.followOn]: BUY_COVER_COMMISSION_RATIO,
};

export const BUY_COVER_COMMISSION_DESTINATION_BY_PRODUCT_TYPE: Record<number, string> = {
  [PRODUCT_TYPES.protocol]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.custodian]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.token]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.sherlockExcess]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.stakewiseEthStaking]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.lcEthStaking]: UNITY_ADDRESS,
  [PRODUCT_TYPES.ethSlashing]: UNITY_ADDRESS,
  [PRODUCT_TYPES.sherlockQuotaShare]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.nativeProtocol]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.retailMutual]: UNITY_ADDRESS,
  [PRODUCT_TYPES.unoreQuotaShare]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.bundledProtocol]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.ethSlashingUmbrella]: UNITY_ADDRESS,
  [PRODUCT_TYPES.openCoverTransaction]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.fundPortfolio]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.sherlockBugBounty]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.defiPass]: DAO_MULTISIG_ADDRESS,
  [PRODUCT_TYPES.followOn]: DAO_MULTISIG_ADDRESS,
};
